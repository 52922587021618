// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Link from "components/layout/Link";
import CommaList from "components/layout/CommaList";
import Text from "components/typography/Text";

// ** Data
import { servicePageNavItems } from "containers/Main/services/servicePageNavItems";

// #####################################################

const ServicePageNavMenu = ({ onMenuItemClick }) => {
	const { t } = useTranslation(["pages"]);

	// #####################################################

	return (
		<CommaList>
			{servicePageNavItems.map((item, index) => {
				return (
					<span
						key={item.key}
						style={{
							display: item.key === "privacy" ? "inline" : null,
						}}
					>
						<Link
							onClick={() => {
								if (onMenuItemClick) {
									onMenuItemClick();
								}
							}}
							{...item.link}
						>
							{t(`pages:${item.textKey}`)}
							{index !== servicePageNavItems.length - 1 ? (
								<Text mr={1}>,</Text>
							) : null}
						</Link>
					</span>
				);
			})}
		</CommaList>
	);
};

// #####################################################

export default ServicePageNavMenu;

// #####################################################
