// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledToggleSwitch = styled.div`
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 36px;
	height: 16px;
	cursor: pointer;

	${({ marginTop }) =>
		marginTop &&
		css`
			margin-top: ${Number.isInteger(Number(marginTop))
				? `${marginTop}px`
				: marginTop || `2px`};
		`}
`;

// #####################################################
