// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledToggleLabelBefore = styled.span`
	content: "";
	position: absolute;
	top: 2px;
	left: 3px;
	width: 12px;
	height: 12px;
	background-color: ${({ theme: { colors } }) => colors.white};
	border-radius: 50%;
	transition: transform 0.2s ease-in-out;
	${({ isChecked }) =>
		isChecked &&
		css`
			transform: translateX(19px);
		`}
`;

// #####################################################
