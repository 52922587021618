// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledToggleInput = styled.input`
	display: none;
`;

// #####################################################
