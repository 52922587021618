import styled from "styled-components";

const CommaList = styled("div")`
	display: inline;
	& > span {
		display: inline-block;
		color: ${({ theme: { colors } }) => colors.primary};
		font-size: 0.9rem;

		a:hover {
			text-decoration: underline;
		}
	}
`;

export default CommaList;
