// ** React Imports
import { useEffect, useState } from "react";

// ** Styled Components
import {
	StyledToggleSwitch,
	StyledToggleLabel,
	StyledToggleInput,
	StyledToggleLabelBefore,
} from "./styles";

// ####################################################

const Toggle = ({ toggled, onToggle, toggleMaster, marginTop, ...rest }) => {
	const [isToggled, toggle] = useState(toggled);

	useEffect(() => {
		toggle(toggled);
	}, [toggled]);

	return (
		<StyledToggleSwitch
			marginTop={marginTop}
			onClick={() => {
				if (typeof toggleMaster === "function") {
					toggleMaster(toggle);
				} else {
					toggle(!isToggled);
					onToggle();
				}
			}}
			{...rest}
		>
			<StyledToggleInput type="checkbox" />
			<StyledToggleLabel isChecked={isToggled}>
				<StyledToggleLabelBefore isChecked={isToggled} />
			</StyledToggleLabel>
		</StyledToggleSwitch>
	);
};

// #####################################################

export default Toggle;

// #####################################################
